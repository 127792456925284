<template>
    <div>
        <span class="text">智慧政务</span>
        <el-carousel trigger="click" :autoplay="true" height="720px" indicator-position="outside" class="Top">
        <el-carousel-item :key="1" >
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case01.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：全国人民代表大会某委员会</div>
                    <div class="box-text-text2">工程内容：屏蔽机房系统工程(装修系统、空调新风系统、门禁系统、电源防雷系统、弱电系统、电视监控系统、漏水监测报警系统、场地环境监控系统、视频会议室系统、供配电及UPS不间断电源系统、消防系统)</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="2">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case02.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：中共某省委员会办公厅</div>
                    <div class="box-text-text3">工程内容：</div>
                        <div class="box-text-text4">一、应急指挥系统
                            <br> 二、屏蔽机房系统工程（装修系统、空调新风系统、门禁系统、电源防雷系统、弱电系统、电视监控系统、漏水监测报警系统、场地环境监控系统、供配电及UPS不间断、电源系统、消防系统）
                            <br>三、国贫县信息化提升工程
                            <br>四、身份认证系统（定制开发）
                            <br>五、运维服务（机房全系统、会议全系统、网络系统、身份认证系统等运行保障）</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="3">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case03.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：某省人民政府</div>
                    <div class="box-text-text2">工程内容：基于反恐和场规模式下的综合防控系统，建设智慧综合安防管理平台，实现人、车无障碍通行的适时识别控制管理，并负责省政府大院安防系统运行维护；本项目的试运行阶段，参加了湖北省军区的反恐应急军演，受到省、市各级领导好评。荣获2016年武汉市安防系统典型示范工程。</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="4">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case04.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：中共某省委组织部</div>
                    <div class="box-text-text3">工程内容：</div>
                        <div class="box-text-text4">一、网络系统集成、视频会议及档案管理系统工程（音视频显示及扩声系统、会议录播系统办公自动化系统、干部管理信息系统、干部档案管理系统及数字化加工、LED大屏幕显示系统、会议室家具）
                        <br>二、运行维护（内外网中电子设备、视屏会议全系统、档案管理系统等运行保障）
                    </div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="5">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case05.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：中共某省委宣传部</div>
                    <div class="box-text-text3">工程内容：</div>
                        <div class="box-text-text4">一、建筑智能化系统工程（综合布线系统、安防监控系统、停车场管理系统、楼宇控制系统、会议室系统含大屏、音响、中控等）
                        <br>二、屏蔽机房系统工程（装修系统、空调新风系统、门禁系统、电源防雷系统、弱电系统、电视监控系统、漏水监测报警系统、场地环境监控系统、供配电及UPS不间断、电源系统、消防系统）
                        <br>三、运行维护（内外网中电子设备、机房全系统、视频会议全系统等运行保障）
                    </div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="6">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case06.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：枣阳市重点人管控围栏热点采集项目</div>
                    <div class="box-text-text2">工程内容：我司在枣阳市重点人管控项目中，根据整个项目的重点区域管控需求、以及各类场景下的管控目的，对辖区安装部署10套点采集设备。通过科学选点、合理布局，对主要区域进行了全面的覆盖。随着系统的不断优化，手机特征码数据、图像数据一并汇入大数据平台，实现对重点人员的自动捕获、模型计算、实时预警、轨迹跟踪动态管控，达到了“来能报警，动知轨迹，走明去向，全程掌握”的重点人员动态管控目标。平台方案依据地理、交通等环境定制需求。</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="7">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case07.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text5">湖北省国家税务局及部分地市国税分局网络系统集成、视频会议系统、建筑智能化、机房工程列表</div>
                    <div class="box-text-text6">
                        <div class="box-text-text1">建设单位：湖北省国家税务局</div>
                        <div class="box-text-text2">工程内容：金税二期网络建设（网络改造、网络安全、视频会议）</div>
                    </div>
                    <div class="box-text-text6">
                        <div class="box-text-text1">建设单位：湖北省国家税务局</div>
                        <div class="box-text-text2">工程内容：机房系统工程</div>
                    </div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="8">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case08.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text5">武汉市国家税务局及所属区网络系统集成、视频会议系统、建筑智能化、机房工程列表</div>
                    <div class="box-text-text6">
                        <div class="box-text-text1">建设单位：武汉市国家税务局</div>
                        <div class="box-text-text2">工程内容：中南地区国税系统培训中心建筑智能化弱电系统、多媒体电教室系统、灯光音响系统、多媒体控制系统</div>
                        
                    </div>
                    <div class="box-text-text6">
                        <div class="box-text-text1">建设单位：武汉市国家税务局</div>
                        <div class="box-text-text2">工程内容：建筑智能化弱电系统、网络集成系统、网络安全系统（防火墙、物理隔离网闸安全系统）呼叫中心系统、门禁可视对讲系统、大屏显示系统、多媒体显示系统、软件开发系统</div>
                        
                    </div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="9">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case09.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text5">湖北省地方税务局及部分地市州税分局网络系统集成、建筑智能化、机房工程列表</div>
                    <div class="box-text-text6">
                        <div class="box-text-text1">建设单位：湖北省地方税务局</div>
                        <div class="box-text-text2">工程内容：网络安全</div>
                        <div class="box-text-text1">建设单位：湖北省潜江市地方税务局</div>
                        <div class="box-text-text2">工程内容：机房系统工程(门禁系统、电视监控系统、侧漏水报警系统、场地监控系统)</div>
                    </div>
                    <div class="box-text-text6">
                        <div class="box-text-text1">建设单位：湖北省宜昌市地方税务局</div>
                        <div class="box-text-text2">工程内容：机房系统工程、呼叫中心系统</div>
                        <div class="box-text-text1">建设单位：湖北省黄石市地方税务局</div>
                        <div class="box-text-text2">工程内容：网络安全系统、网络系统集成及UPS不间断电源</div>
                    </div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="10">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case10.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：中共某市委办公厅</div>
                    <div class="box-text-text2">工程内容：屏蔽机房系统工程（装修系统、空调新风系统、门禁系统、电源防雷系统、弱电系统、监控系统、漏水监测报警系统、场地环境监控系统、供配电及UPS不间断、电源系统、消防系统）</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="11">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case11.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：湖北省司法厅</div>
                    <div class="box-text-text2">工程内容：建筑智能化布线系统、多媒体会议室系统、办公自动化系统、社区矫正管理系统、司法鉴定服务管理系统、
                        行政许可审批系统、会议室音视频改造。</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="12">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case12.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：某省国家安全厅、某市安全局、某州安全局、某地市安全局</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、涉密机房系统工程（结构装饰装修系统、供配电系统、照明及应急照明系统、接地系统、防雷系统、UPS不间断电源系统、消防报警系统、气体自动灭火系统、环境监控系统、电视监控系统、
                        门禁系统）、多功能会议室系统
                    </div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="13">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case13.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：湖北省工商行政管理局及部分地市局</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、网络系统集成、机房系统工程、安防监控系统、会议室音视频系统。</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="14">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case14.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：湖北省人力资源和社会保障厅</div>
                    <div class="box-text-text2">工程内容：建筑智能化弱电系统、网络系统集成、会议音视频系统、多功能由子考场系统、电气安装系统、安防监控系统、电子考场远程监控系统</div>
                    <div class="box-text-text1">建设单位：荆州人力资源和社会保障局</div>
                    <div class="box-text-text2">工程内容：机房系统工程（装饰装修系统、供电系统、照明及应急系统、新风系统、精密空调系统、防雷系统、门禁管理系统、消防报警、灭火系统、电视监控系统、综合布线系统）</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="15">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case15.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：武汉市人力资源和社会保障灾备中心</div>
                    <div class="box-text-text3">工程内容：</div>
                        <div class="box-text-text4">一、机房系统工程（装饰装修系统、供电系统、照明及应急系统、新风系统、精密空调系统、防雷系统、门禁管理系统、消防报警、灭火系统、电视监控系统、综合布线系统）
                        <br>二、运维服务（机房全系统运行保障）
                    </div>
                    <div class="box-text-text1">建设单位：武汉市劳动和社会保障局</div>
                    <div class="box-text-text2">工程内容：全市医保定点医院网络系统集成工程及网络维护</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="16">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case16.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：湖北省商务厅</div>
                    <div class="box-text-text2">工程内容：机房系统工程（装饰装修系统、电气系统、新风系统、门禁系统、防雷接地系统、UPS不间断电源系统、消防报警系统）</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="17">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case17.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：湖北省卫生厅</div>
                    <div class="box-text-text2">工程内容：机房系统工程（装饰装修系统、供配电系统（含照明及应急照明）、视频监控系统、防、接地系统、消防报警系统、精密空调系统、UPS不断电源系统、新风系统、门禁管理系统、弱电系统、场地监控系统、配套设施系统。</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="18">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case18.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：河南省遥感测绘院</div>
                    <div class="box-text-text2">工程内容：工程内容包括河南省国土空间基础信息平台和“一张图”实施监督信息系统专用基础设施采购安装调试。主要设备:存储、服务器、交换机、边界墙、云、数据库等。国土空间规划是国家空间发展的指南、可持续发展的空间蓝图，是各类开发保护建设活动的基础依据，通过本项目的建设，建立国土空间门户、数据资源分析应用、三维立体自然资源“一张图”、应用中心、服务中心、在线数据交换等功能，打造数据更全面、应用更广泛、共享更顺畅的国土空基础信息平台。面向省政府以及相关政府部门形成内容全量、更新及时、权威准确的省国士空间基础信息平台，有力支撑政府各部门科学规划城市发展，有效监管国土空间开发利用活动，提高政府管理决策水平。</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="19">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case19.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1" style="text-indent:2rem;">xx机房位于新建大楼的一层和二层左侧。根据设计规划，其中两层楼共计面积为 1060 平方。一层业务机房区域360平方：包含外网机房、传输机房、接入机房、调试间、电源室、网络机房、维修通道及缓冲区，二层业务机房和辅助办公区域700平方：包含主渠道机房、监控室、多功能办报室、值班室、会议室、卫生间、公共走道、办公室、职能室、学习（治谈）室、档案室、运维备件库。</div>
                    
                </div>
            </div>
        </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>

export default {
// data(){
//     return {
//             screenheight: document.documentElement.clientHeight + 'px',
//             fileList:[{id: 1,src: require('@/assets/img/case01.jpg')},{id: 2,src: require('@/assets/img/case01.jpg')}]
//         }

// },
// mounted(){
//         window.onresize = () => {
//             return (()=>{
//                 this.screenheight = document.documentElement.clientHeight + 'px'
//             })
//         }
//     }
}
</script>

<style scoped lang="less">

.box{
    width: 100%;
    //height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    &-img{
        
        width: 95%;
        //height: 80%;
        display: flex;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;
        top: 0rem;
        &-img1 {
            margin-top: 1rem;
            width: 100%;
            //height:100%;
        }
    }
    &-text{
        top: 4rem;
        line-height:1.5rem;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 95%;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        &-text1{
            text-align: start;
            width: 100%;
            height: 30px;
            margin-top: 0rem;
            //display: flex;
            position: relative;
            float: inline-start;
        }
        &-text2{
            //display: flex;
            flex-wrap: wrap;
            width: 100%;
            //height: 50px;
            position: relative;
        }
        &-text3{
            display: flex;
            position: relative;
            width: 10%;
        }
        &-text4{
            display: flex;
            position: relative;
            width: 90%;
        }
        &-text5{
            display: flex;
            position: relative;
            width: 100%;
            justify-content: center;
            font-weight: bold;
        }
        &-text6{
            //display: flex;
            position: relative;
            width: 49%;
            top: 1rem;
        }
    }
}
.text{
    margin-top: 2rem;
    line-height:2.5rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    //color: #ffffff;
}
.block{
    width: 100%;
}
 .el-carousel__item {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
    border: #475669;
 }
 .el-carousel__item:nth-child(n) {
     background-color: #ffffff;
  }
  .el-carousel__container{
	height: auto!important;
}
.Top{
    margin-top: 1rem;
}
</style>